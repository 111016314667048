:root {
  /*REFACTOR VARIABLES ANT DESING*/
  --primary-color: #EB0089;
  --link-color: #EB0089;
  --success-color: #52c41a;
  --warning-color: #faad14;
  --error-color: #f5222d;
  --font-size-base: 14px;
  --heading-color: rgba(0, 0, 0, 0.85);
  --text-color: rgba(0, 0, 0, 0.65);
  --text-color-secondary: rgba(0, 0, 0, 0.45);
  --disabled-color: rgba(0, 0, 0, 0.25);
  --border-radius-base: 8px;
  --border-color-base: #d9d9d9;
  --box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
  /*REFACTOR VARIABLES ANT DESING*/

  --color-disabled: #c6cacc;

  --border-radius: 4px;

  --grid-gutter: 20px;
  --grid-wrapper: 1024px;

  --text-color-base: #3e4345;
  -text-color-secundary: #919699;

  --elevation-2p: 0 2px 4px rgba(8, 35, 48, 0.24);
  --elevation-4p: 0 4px 8px rgba(8, 35, 48, 0.24);

  --theme-sidebar-box-shadow:0 1px 4px 0px rgba(0, 0, 0, 0.16);
  --theme-sidebar-bg-color: #1d2939;
  --secondary-color: #1d2939;
  --theme-sidebar-text-color: white;
  --theme-menu-bg-color: white;
  --theme-background-base: #e9ecef;
  --header-height: 60px;
  --header-bg: white;
  --header-color: #6c757d;
}
