.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 9px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}
.list-gallery {
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 9px;
  margin-right: 8px;
  position: relative;
  width: 150px;
  list-style: none;
  .content-pin-move {
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    position: absolute;
    left: 55%;
    top: 9px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-gallery {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

.file {
  width: 100%;
  height: 125px;
  object-fit: contain;
  object-position: center;
  padding: 5px;
  display: block;
}
.trash {
  position: absolute;
  text-align: center;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  top: 9px;
  left: 25%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aside {
  padding: 1rem 1rem 1rem 0rem;
  width: 100%;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #808080;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
  min-height: 15rem;
  justify-content: center;
  cursor: default;
}
.title {
  font-size: 1rem;
  color: #6d6d6d;
}
.title2 {
  font-size: 1rem;
  margin-bottom: 5px;
  color: var(--color-primary);
}
.error {
  color: red;
}
.withoutresults {
  color: #6d6d6d;
}

.imagePreview {
  width: 100px;
  height: 100px;
}
.progress-gallery {
  margin-top: -15px;
  & > div > div > div > div > div {
    background-color: var(--primary-color) !important;
  }
}
.list-number {
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  background: var(--primary-color);
  color: white;
}
.nameFile {
  display: block;
  font-size: 10px;
  word-break: break-all;
}
