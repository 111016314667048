.img {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.sort {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0;
}
.content-gallery {
  padding-left: 0;
}
.list-gallery {
  display: inline-block;
  margin-bottom: 9px;
  margin-right: 8px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  .content-pin-move {
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    position: absolute;
    left: 30%;
    top: 9px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .trash {
    position: absolute;
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
    cursor: pointer;
    top: 9px;
    left: 55%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.imagePreview {
  width: 200px;
  height: auto;
}
.icon-yt{
  height: 100px;
  width: 100%;
  font-size: 100px;
  border-bottom: 1px solid #f1f1f1;
}
.yt-video{
  height: 100px;
  width: 100%;
}
.yt-video2{
  height: 200px;
  width: 100%;
}