.noty_theme__relax.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative; }
  .noty_theme__relax.noty_bar .noty_body {
    padding: 10px; }
  .noty_theme__relax.noty_bar .noty_buttons {
    border-top: 1px solid #e7e7e7;
    padding: 5px 10px; }

.noty_theme__relax.noty_type__alert,
.noty_theme__relax.noty_type__notification {
  background-color: #fff;
  border: 1px solid #dedede;
  color: #444; }

.noty_theme__relax.noty_type__warning {
  background-color: #FFEAA8;
  border: 1px solid #FFC237;
  color: #826200; }
  .noty_theme__relax.noty_type__warning .noty_buttons {
    border-color: #dfaa30; }

.noty_theme__relax.noty_type__error {
  background-color: #FF8181;
  border: 1px solid #e25353;
  color: #FFF; }
  .noty_theme__relax.noty_type__error .noty_buttons {
    border-color: darkred; }

.noty_theme__relax.noty_type__info,
.noty_theme__relax.noty_type__information {
  background-color: #78C5E7;
  border: 1px solid #3badd6;
  color: #FFF; }
  .noty_theme__relax.noty_type__info .noty_buttons,
  .noty_theme__relax.noty_type__information .noty_buttons {
    border-color: #0B90C4; }

.noty_theme__relax.noty_type__success {
  background-color: #BCF5BC;
  border: 1px solid #7cdd77;
  color: darkgreen; }
  .noty_theme__relax.noty_type__success .noty_buttons {
    border-color: #50C24E; }
