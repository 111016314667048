.trash {
  position: absolute;
  text-align: center;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  top: 9px;
  left: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: block;
}
.without-results {
  text-align: center;
}
.preview {
  width: 250px;
  height: auto;
}
