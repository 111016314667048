.card-bg-1 {
  background-color: #17a2b8 !important;
}
.card-bg-2 {
  background-color: #6f42c1;
}
.card-bg-3 {
  background-color: #0866c6 !important;
}
.card-bg-4 {
  background-color: #18527b !important;
}
.card {
  color: white;
  position: relative;
  overflow: hidden;
  min-height: 130px;
  padding: 5px;
  h1 {
    color: white;
    font-size: 12px;
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
  }
  .icon {
    margin-right: 8px;
  }
  .text {
    font-size: 14px;
  }
}

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.mb {
  margin-bottom: 1rem;
}
.content-select {
  text-align: right;
}
