.h-100{
  height: 100%;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.text-right{
  text-align: right;
}
.discount-applied-order{
  width: 100%;
  margin-top: 1.5rem;
  padding-top:20px;
  padding-bottom:10px;
}

.mb-0{
  margin-bottom: 0;
}