:root {
  @t-root;
}

html,
body,
main {
  @t-base;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern';
  -webkit-font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern-1';
  border: 0;
  vertical-align: baseline;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  background-color: var(--theme-background-base);
}

h1 {
  font-size: 5step;

  @t-above (tablet) {
    font-size: 5step;
  }

  @t-above (desktop) {
    font-size: 5step;
  }

  @t-above (lg-desktop) {
    font-size: 5step;
  }
}

h2 {
  font-size: 4step;

  @t-above (tablet) {
    font-size: 4step;
  }

  @t-above (desktop) {
    font-size: 4step;
  }

  @t-above (lg-desktop) {
    font-size: 4step;
  }
}

h3 {
  font-size: 2step;

  @t-above (tablet) {
    font-size: 2step;
  }

  @t-above (desktop) {
    font-size: 2step;
  }

  @t-above (lg-desktop) {
    font-size: 2step;
  }
}

h4 {
  font-size: 1step;

  @t-above (tablet) {
    font-size: 1step;
  }

  @t-above (desktop) {
    font-size: 1step;
  }

  @t-above (lg-desktop) {
    font-size: 1step;
  }
}

/* *******SCROLL GENEREL******* */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--primary-color);
  height: 3px;
}
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

/* *******END SCROLL GENEREL******* */
.text-center {
  text-align: center;
}
.inline-block {
  display: inline-block;
  margin-bottom: 1em;
}

.left-nav-submenu ul{
  background-color: rgb(44, 58, 76)!important;
  box-shadow: none !important;
}
.move-content-right{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.ant-upload.ant-upload-select {
  display: block;
  width: 100%;
  height: 100%;
}
.ql-container.ql-snow {
  height: 15rem!important;
}
.rc-color-picker-trigger{
  width: 500px;
}