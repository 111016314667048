/* .u-center--middle {
  lost-align: middle-center flex;
}

.u-align-flex--right {
  margin-left: auto !important;
}
.u-fluid-with {
  width: auto;
}

.u-gap-small {
  padding: 0 5px;
  &:last-child {
    padding-right: 0px;
  }
}

.u-no--padding {
  padding:0 !important;
}

.u-gap--top-small {
  margin-top: 20px;
}

.u-align-flex--right {
  margin-left: auto !important;
}

.u-fluid-with {
  width: auto;
}

.u-color-error {
  color: var(--color-error) !important;
}
 */

 .list-card {
   list-style: none;
   padding: 0;
   margin: 0;
 }


.book {
  display: flex;
  align-items: center;

   &__image {
     margin: 0;
     padding: 0 25px;
     max-width: 120px;
    box-sizing: border-box;

     img {
       width: 100%;
     }
   }

 }
