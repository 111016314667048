.content-file {
  padding: 5px;
  border: solid 2px var(--primary-color);
  margin: 10px;
  width: 180px;
}
.image {
  width: 100%;
  height: 125px;
  object-fit: contain;
  object-position: center;
  padding: 5px;
  display: block;
}
.content-gallery {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.list-gallery {
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 9px;
  margin-right: 8px;
  position: relative;
  width: 150px;
  list-style: none;
  .content-pin-move {
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    position: absolute;
    left: 42%;
    top: 9px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.file {
  width: 100%;
  height: 125px;
  object-fit: contain;
  object-position: center;
  padding: 5px;
  display: block;
}
.list-number {
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  background: var(--primary-color);
  color: white;
}
.nameFile {
  display: block;
  font-size: 10px;
  word-break: break-all;
  &.pink {
    color: var(--primary-color);
    text-align: center;
  }
}
